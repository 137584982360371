@import url("https://fonts.googleapis.com/css?family=Zen+Kaku+Gothic+New:regular,bold,italic&subset=latin,latin-ext");

body,
html {
    font-family: "Zen Kaku Gothic New", sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.App {
    text-align: center;
}

/*   .App.expanded {}
    .App.expanded .App-map {
      height: 20%;
    }
    .App.expanded .App-info {
      height: 75%;
    }
 */
.App.fullscreen {
    .App.fullscreen .App-map {
        bottom: 0;
        height: auto;
    }

    .App.fullscreen .App-info {
        height: 0;
    }
}

.App-header {
    align-items: center;
    background-color: #351353;
    color: white;
    display: flex;
    flex-direction: column;
    font-size: calc(10px + 2vmin);
    font-weight: bold;
    height: 50px;
    justify-content: center;
}

.App-header a {
    text-decoration: none;
}

.App-header .app-name {
    color: #ff334b;
    line-height: 2vh;
}

.App-header .App-header .settings {
    height: 3vh;
    position: absolute;
    top: 1vh;
    width: 3vh;
}

.App-header .center-on-me {
    filter: grayscale(1);
    opacity: 0.5;
    transition: all 0.5s;
}

.App-header .center-on-me.active {
    filter: none;
    opacity: 1;
}

.App-header .settings {
    right: 1.5vh;
}

.App-header .login {
    right: 6.5vh;
}

.App-header img {
    height: 100%;
    width: 100%;
}

.App-link {
    color: #61dafb;
}

#menu_container {
    position: absolute;
    display: flex;
    align-items: center;
    right: 0;
    top: 0;
    padding: 0px 30px 0 0;
}

#contextmenu_container {
    position: absolute;
    display: flex;
    align-items: center;
    left: 0;
    top: 0;
    padding: 0px 30px 0 0;
}

#mySvg {
    font-size: 25px;
    margin-left: 5px;
}

.layer [data-rsbs-overlay] {
    width: 90%;
    margin: 0 auto;
    padding: 15px 15px 25px 15px;
    height: auto;
    z-index: 1000;
}

.arlayer [data-rsbs-overlay] {
    width: 95%;
    margin: 0 auto;
    padding: 15px 15px 25px 15px;
    height: 90%;
}

.App-map {
    box-shadow: rgba(0, 0, 0, 0.5) 0 2px 6px;
    height: 93%;
    left: 0;
    position: absolute;
    right: 0;
    /* transition: height 0.5s; */
    /* z-index: 100; */
}

.Map-container {
    height: 100%;
    width: 100%;
}

.Map-pin {
    background: gray;
    border: solid 1px white;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.5) 0 2px 6px;
    height: 10vw;
    left: 50%;
    max-height: 64px;
    max-width: 64px;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
    width: 10vw;
    z-index: 1;
}

.Map-pin img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    z-index: 1;
}

.App-info {
    background: white;
    bottom: 0;
    flex-direction: column;
    height: 40%;
    left: 0;
    overflow: scroll;
    position: absolute;
    right: 0;
    transition: height 0.5s;
}

.App-info-container {
    padding: 0 1rem 1rem;
}

.App-info-container article {
    text-align: center;
}

.App-info-container img {
    max-width: 60%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.App-settings-container {
    padding: 0 1rem 1rem;
}

.App-settings-container article {
    text-align: left;
}

.App-settings-container img {
    max-width: 60%;
}

.App-info-how-to {
    display: block;
    height: 60vh;
    margin: auto;
    width: auto;
}

.modal {
    background: rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1000;
}

.modal .content {
    background: white;
    border-radius: 1rem;
    bottom: 3vh;
    box-shadow: rgba(0, 0, 0, 0.5) 0 2px 6px;
    left: 3vh;
    padding: 1rem;
    position: absolute;
    right: 3vh;
    text-align: left;
    top: 3vh;
}

.modal .close-x {
    position: absolute;
    right: 1vh;
    top: 1vh;
}

.modal .close-x img {
    height: 32px;
    width: 32px;
}

.slider-horizontal {
    height: 1rem;
    margin: 1.5rem 0;
}

.slider-track {
    height: 1rem;
}

/* selected */
.slider-track-0 {
    background: gray;
    border-radius: 0.5rem;
    height: 1rem;
}

/* remaining */
.slider-track-1 {
    background: silver;
    border-radius: 0.5rem;
    box-shadow: inset 0 6px 6px -6px rgba(0, 0, 0, 0.5);
    height: 1rem;
}

.slider-thumb {
    background: white;
    border: solid 1px black;
    border-radius: 0.5rem;
    bottom: -0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    font-weight: bold;
    height: 2rem;
    line-height: 2rem;
    outline: none;
    text-align: center;
    user-select: none;
    width: 2rem;
}

.slider-legend {
    height: 47px;
    margin: 1rem 0;
    position: relative;
}

.slider-car,
.slider-bike,
.slider-walker {
    background-position: center;
    background-repeat: no-repeat;
    bottom: 0;
    display: block;
    position: absolute;
}

.slider-s_radar,
.slider-m_radar,
.slider-l_radar {
    background-position: center;
    background-repeat: no-repeat;
    bottom: 0;
    display: block;
    position: absolute;
}

.slider-car {
    background-image: url(./assets/slider-car.png);
    height: 64px;
    left: 0;
    width: 64px;
}

.slider-bike {
    background-image: url(./assets/slider-bike.png);
    height: 64px;
    left: 44%;
    width: 64px;
}

.slider-walker {
    background-image: url(./assets/slider-walker.png);
    height: 64px;
    right: 0;
    width: 64px;
}

.slider-s_radar {
    background-image: url(./assets/slider-s_radar.png);
    height: 64px;
    left: 0;
    width: 64px;
}

.slider-m_radar {
    background-image: url(./assets/slider-m_radar.png);
    height: 64px;
    right: 45%;
    width: 64px;
}

.slider-l_radar {
    background-image: url(./assets/slider-l_radar.png);
    height: 64px;
    right: 0;
    width: 64px;
}

.map-center {
    height: 24px;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.5s;
    user-select: none;
    width: 24px;
    z-index: 1;
}

.map-center.active {
    opacity: 1;
}

@media screen and (orientation: landscape) {
    .App-header {
        font-size: 16px;
    }

    .App-info {
        left: 20%;
        right: 20%;
    }

    i {
        max-height: 16px;
        max-width: 16px;
    }

    .Map-pin {
        max-height: 32px;
        max-width: 32px;
    }
}

.auth-form-container,
.login-form,
.register-form {
    display: flex;
    width: 50%;
    flex-direction: column;
    border: #2e355e 1px solid;
}

.loginlabel {
    text-align: center;
    padding: 0.25rem 0;
}

.loginheader {
    text-align: center;
    padding: 0.25rem 0;
}

.logininput {
    margin: 0.5rem 0;
    padding: 1rem;
    border: none;
    text-align: center;

    border-radius: 10px;
}

button {
    border: none;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
    color: #7439db;
}

.link-btn {
    background: none;
    color: #7439db;
    text-decoration: underline;
}

.login-portal {
    position: "absolute";
    top: 20;
    right: 20;
    width: 200;
    border: "solid 1px hsla(0, 0%, 0%, 0.25)";
    padding: 20;
    background: "#f0f0f0";
}

#logo {
    position: absolute;
    padding: 10px;
    width: 200px;
    font-size: 24px;
    background-color: rgba(255, 255, 255, 0.9);
    left: 90%;
    margin: 20px 0 0 -100px;
    text-align: center;
}

.radial-menu {
    background-color: #351353;
}

.MuiDialogTitle-root {
    background-color: #351353;
    color: #ff334b;
    text-align: center;
}

.MuiTextField-root {
    margin: 15;
    width: "300px";
}

.MuiButtonBase-root {
    margin: 2;
}

.dialogTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
