.my-location {
    animation: fade 1.25s infinite;
    display: block;
    font-style: normal;
    height: 4vw;
    left: 50%;
    max-height: 24px;
    max-width: 24px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
    width: 4vw;
    z-index: 10;
}

@keyframes fade {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.25;
    }
    100% {
        opacity: 1;
    }
}